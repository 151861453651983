import styled from "styled-components";

import BonusBox from "./Bonuses/Bonus";

const StyledHeaderText = styled.div`
  color: white;
  font-size: 5em;
  padding: 1em 0 0.3em 0;
  text-shadow: 0 0 1px #fff, 0 0 1px #fff, 0 0 16px #fff, 0 0 30px #7289da,
    0 0 52px #7289da, 0 0 62px #7289da, 0 0 82px #7289da, 0 0 111px #7289da;
  @media (max-width: 800px) {
    font-size: 2em;
  }
`;

const HeaderText = () => {
  return <StyledHeaderText>DuoBonus.com</StyledHeaderText>;
};

const HeaderBar = styled.div`
  background: linear-gradient(180deg, #1e2124 0%, #7289da 100%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-family: "Geologica", sans-serif;
  ${({ height }) =>
    height &&
    `
    height: ${height}vh;
  `}
`;

const FeaturedWrapper = styled.div`
  max-width: 950px;
  margin: 2em auto;
  box-shadow: 0 0 30px 10px #fff;
  border-radius: 8px;
  font-family: "Rajdhani", sans-serif;

  @media (max-width: 800px) {
    margin: 2em 0.5em;
  }
`;

const LandingPage = () => {
  return (
    <HeaderBar height={100}>
      <HeaderText />
    </HeaderBar>
  );
};

const FeaturedBonus = ({ bonus }) => {
  return (
    <FeaturedWrapper>
      <BonusBox index={"featured"} bonus={bonus} featured={true} />
    </FeaturedWrapper>
  );
};

const Header = ({ featuredBonus }) => {
  return (
    <HeaderBar>
      <HeaderText />
      {featuredBonus ? (
        <>
          <FeaturedBonus bonus={featuredBonus[0]} />
        </>
      ) : null}
    </HeaderBar>
  );
};

export { HeaderText, HeaderBar, LandingPage, Header };
