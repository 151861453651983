import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";

import { Header } from "../Header";
import {
  filterHighlights,
  filterPayNPlay,
  filterSpecificLicense,
  filterNoCondition,
  filterFreeSpins,
} from "./filtering";
import BonusBox from "./Bonus";
import Footer from "../Footer";

const Wrapper = styled.div`
  max-width: 950px;
  display: flex;
  margin: 0 auto;
  padding: 50px 5px;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 800px) {
  }
`;

const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const StyledListItem = styled(motion.li)`
  margin: 30px 0.5em;
`;

const FilterWrapper = styled.div`
  display: flex;

  @media (max-width: 800px) {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  *::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  * {
    margin-right: 1em;
  }

  button {
    margin-right: 15px;
    border: 1px solid #7289da;
    background-color: #1e2124;
    border-radius: 5px;
    color: #7289da;
    padding: 15px;
    font-size: 1.2em;
    cursor: pointer;
    transition: ease 0.2s all;
  }
`;

const ListItemAnimation = {
  initial: { opacity: 0, x: -1000 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -100 },
  transition: { delay: 1000 },
};

const Background = styled.div`
  background-color: #1e2124;
  min-height: 100vh;
`;

const WhiteHeader = styled.h2`
  color: #fff;
`;

const Bonus = ({ bonuses }) => {
  const [filteredBonuses, setFilteredBonuses] = useState(bonuses);
  const [filterSettings, setFilterSettings] = useState([]);
  const [featuredBonus, setFeaturedBonus] = useState(null);

  useEffect(() => {
    let tempFilteredBonuses = [];

    tempFilteredBonuses = filterHighlights(bonuses, filterSettings);

    if (filterSettings.includes("freeSpins")) {
      tempFilteredBonuses = filterFreeSpins(tempFilteredBonuses);
    }

    if (filterSettings.includes("payNplay")) {
      tempFilteredBonuses = filterPayNPlay(tempFilteredBonuses);
    }

    if (filterSettings.includes("mgaLicense")) {
      tempFilteredBonuses = filterSpecificLicense(tempFilteredBonuses, "MGA");
    }

    if (filterSettings.includes("estoniaLicense")) {
      tempFilteredBonuses = filterSpecificLicense(
        tempFilteredBonuses,
        "Estonia"
      );
    }

    if (filterSettings.includes("noCondition")) {
      tempFilteredBonuses = filterNoCondition(tempFilteredBonuses);
    }

    setFilteredBonuses(tempFilteredBonuses);

    const featured = bonuses.filter((bonus) => bonus.highlight);
    setFeaturedBonus(featured);
  }, [filterSettings, bonuses]);

  const handleFilterChange = (newFilter) => {
    setFilterSettings((previousSettings) => {
      return previousSettings.includes(newFilter)
        ? previousSettings.filter((setting) => setting !== newFilter)
        : [...previousSettings, newFilter];
    });
  };

  const selectedStyle = (filterName) => {
    return filterSettings.includes(filterName)
      ? { backgroundColor: "#7289da", color: "#fff" }
      : null;
  };

  return (
    <Background>
      <Header featuredBonus={featuredBonus} />
      <Wrapper>
        <FilterWrapper>
          <WhiteHeader>Filters:</WhiteHeader>
          <button
            onClick={() => handleFilterChange("freeSpins")}
            style={selectedStyle("freeSpins")}
          >
            Free spins
          </button>
          <button
            onClick={() => handleFilterChange("payNplay")}
            style={selectedStyle("payNplay")}
          >
            Pay & Play
          </button>
          <button
            onClick={() => handleFilterChange("mgaLicense")}
            style={selectedStyle("mgaLicense")}
          >
            MGA lincense
          </button>
          <button
            onClick={() => handleFilterChange("estoniaLicense")}
            style={selectedStyle("estoniaLicense")}
          >
            Estonia lincense
          </button>
          <button
            onClick={() => handleFilterChange("noCondition")}
            style={selectedStyle("noCondition")}
          >
            No condition
          </button>
        </FilterWrapper>
        <StyledList>
          <AnimatePresence>
            {filteredBonuses.map((bonus, index) => {
              return (
                <StyledListItem
                  key={index}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={ListItemAnimation}
                  delay="1000"
                >
                  <BonusBox index={index} bonus={bonus} />
                </StyledListItem>
              );
            })}
          </AnimatePresence>
        </StyledList>
        <Footer />
      </Wrapper>
    </Background>
  );
};

export default Bonus;
