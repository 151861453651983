export const filterHighlights = (bonuses) => {
  return bonuses.filter((bonus) => {
    if (bonus.highlight) {
      return false;
    } else {
      return true;
    }
  });
};

export const filterPayNPlay = (bonuses) => {
  return bonuses.filter((bonus) => {
    if (bonus.payNplay) {
      return true;
    } else {
      return false;
    }
  });
};

export const filterSpecificLicense = (bonuses, license) => {
  return bonuses.filter((bonus) => {
    if (bonus.license === license) {
      return true;
    } else {
      return false;
    }
  });
};

// check if bonus.perks.freeSpins, bonus.perks.bonus or bonus.perks.wager has .condition
export const filterNoCondition = (bonuses) => {
  return bonuses.filter((bonus) => {
    if (bonus.perks) {
      if (
        bonus.perks?.freeSpins?.condition ||
        bonus.perks?.wager?.condition ||
        bonus.perks?.bonus?.condition
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  });
};

export const filterFreeSpins = (bonuses) => {
  return bonuses.filter((bonus) => {
    if (bonus?.perks?.freeSpins?.value) {
      return true;
    } else {
      return false;
    }
  });
};
