import Rapid from "./images/Rapid.png";
import Nitro from "./images/Nitro.png";
import NeonVegas from "./images/NeonVegas.png";
import Fruta from "./images/Fruta.png";
import Highroller from "./images/Highroller.png";
import Posido from "./images/Posido.png";
import Trickz from "./images/trickz3.png";
import Friday from "./images/friday2.png";
import Windetta from "./images/windetta.png";
import Smokace from "./images/smokace2.png";
import Winlegends from "./images/winlegends.png";
import Winnerz from "./images/winnerz2.png";
import Wisho from "./images/wisho.webp";
import Hejgo from "./images/hejgo.png";
import Lemon from "./images/lemon.png";
import Energy from "./images/energy.png";
import Rebellion from "./images/rebellion.png";
import Together from "./images/together.png";
import SlotsPalace from "./images/slotspalace.png";
import SevenSigns from "./images/7signs.png";
import MyEmpire from "./images/myempire.png";
import FiveCringos from "./images/5cringos.png";
import Powerup from "./images/powerup.png";
import Ultra from "./images/ultra.png";
import Igni from "./images/igni.png";
import Lumi from "./images/lumi.png";
import Barz from "./images/barz.png";
import Richprize from "./images/rich.png";
import SGCasino from "./images/sgcasino.png";
import Wazamba from "./images/wazamba2.png";
import Ursa from "./images/ursa.png";

const bonuses = [
  {
    name: "ursa",
    type: "bonus",
    img: Ursa,
    link: "https://ivyaffsolutions.com/C.ashx?btag=a_35103b_848c_&affid=6261&siteid=35103&adid=848&c=",
    payNplay: true,
    license: "Isle of Man",
    highlight: true,
    perks: {
      bonus: {
        maxSum: 200,
        percentage: 100,
      },
      wager: { value: 40 },
      freeSpins: {
        value: 50,
      },
    },
  },
  {
    name: "rapid",
    type: "bonus",
    img: Rapid,
    link: "https://afftrackrc.21.partners/C.ashx?btag=a_17791b_1243c_&affid=4204&siteid=17791&adid=1243&c=",
    payNplay: true,
    license: "MGA",
    perks: {
      bonus: {
        maxSum: 300,
        percentage: 100,
      },
      wager: { value: 40, condition: "Code: RAPID" },
    },
  },
  {
    name: "nitro",
    type: "bonus",
    img: Nitro,
    link: "https://afftracknc.21.partners/C.ashx?btag=a_17791b_944c_&affid=4204&siteid=17791&adid=944&c=",
    payNplay: true,
    license: "MGA",
    perks: {
      bonus: {
        maxSum: 300,
        percentage: 100,
      },
      wager: { value: 40, condition: "Code: NITRO" },
    },
  },
  {
    name: "neonvegas",
    type: "bonus",
    img: NeonVegas,
    link: "https://afftracknv.21.partners/C.ashx?btag=a_17791b_1241c_&affid=4204&siteid=17791&adid=1241&c=",
    payNplay: true,
    license: "MGA",
    perks: {
      bonus: {
        maxSum: 300,
        percentage: 100,
      },
      wager: { value: 40, condition: "Code: NEON" },
    },
  },
  {
    name: "fruta",
    type: "bonus",
    img: Fruta,
    link: "https://track.affroller.com/visit/?bta=36284&brand=fruta",
    payNplay: true,
    license: "Estonia",
    perks: {
      freeSpins: {
        value: 200,
      },
    },
  },
  {
    name: "highroller",
    type: "bonus",
    img: Highroller,
    link: "https://track.affroller.com/visit/?bta=36284&brand=highroller",
    payNplay: true,
    license: "Estonia",
    perks: {
      freeSpins: {
        value: 200,
      },
    },
  },
  {
    name: "posido",
    type: "bonus",
    img: Posido,
    link: "https://psdcur.servclick1move.com/?mid=33481_994185",
    payNplay: true,
    license: "Estonia",
    perks: {
      freeSpins: {
        value: 200,
      },
      wager: {
        value: 30,
      },
    },
  },
  {
    name: "lumi",
    type: "bonus",
    img: Lumi,
    link: "https://ivyaffsolutions.com/C.ashx?btag=a_33230b_811c_&affid=6261&siteid=33230&adid=811&c=",
    payNplay: true,
    license: "MGA",
    perks: {
      bonus: {
        maxSum: 2500,
        percentage: 100,
      },
      freeSpins: {
        value: 75,
      },
      wager: { value: 40 },
    },
  },
  {
    name: "igni",
    type: "bonus",
    img: Igni,
    link: "https://ivyaffsolutions.com/C.ashx?btag=a_32755b_826c_&affid=6261&siteid=32755&adid=826&c=",
    perks: {
      freeSpins: {
        value: 100,
      },
      wager: {
        value: 40,
      },
      bonus: { maxSum: 500, percentage: 100 },
    },
    payNplay: true,
    license: "MGA",
  },
  {
    name: "barz",
    type: "bonus",
    img: Barz,
    link: "https://ivyaffsolutions.com/C.ashx?btag=a_33770b_632c_&affid=6261&siteid=33770&adid=632&c=",
    payNplay: true,
    license: "MGA",
    perks: {
      freeSpins: {
        value: 100,
      },
      wager: { value: 40 },
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
    },
  },
  {
    name: "energy",
    type: "bonus",
    img: Energy,
    link: "https://charity.energy.partners/redirect.aspx?pid=49706&bid=5670",
    payNplay: true,
    license: "MGA",
    perks: {
      bonus: {
        maxSum: 300,
        percentage: 100,
      },
      freeSpins: {
        value: 300,
      },
      wager: { value: 30 },
    },
  },
  {
    name: "powerup",
    type: "bonus",
    img: Powerup,
    link: "https://media1.powerup-partners.com/redirect.aspx?pid=2886&bid=1579",
    perks: {
      freeSpins: { value: 100 },
      wager: { value: 35 },
      bonus: { percentage: 120, maxSum: 120 },
    },
    license: "MGA",
    payNplay: true,
  },
  {
    name: "sgcasino",
    type: "bonus",
    img: SGCasino,
    link: "https://sgc.servclick1move.com/?mid=122081_897561",
    perks: {
      freeSpins: { value: 200 },
      wager: { value: 35 },
      bonus: { percentage: 100, maxSum: 500 },
    },
    license: "Curacao",
    payNplay: true,
  },
  {
    name: "wazamba",
    type: "bonus",
    img: Wazamba,
    link: "https://wzbw.servclick1move.com/?mid=122081_897553",
    perks: {
      freeSpins: { value: 200 },
      wager: { value: 35 },
      bonus: { percentage: 100, maxSum: 500 },
    },
    license: "Estonia",
    payNplay: true,
  },
  {
    name: "trickz",
    type: "bonus",
    img: Trickz,
    link: "https://go.spinwise.partners/visit/?bta=35331&brand=trickz",
    perks: {
      freeSpins: { value: 100, condition: "20€ Min. Deposit" },
      wager: { value: 20 },
    },
    license: "Estonia",
    payNplay: true,
  },
  {
    name: "wisho",
    type: "bonus",
    img: Wisho,
    link: "https://click.wisho.partners/afs/come.php?cid=12409&ctgid=100&atype=1&brandid=11",
    perks: {
      wager: { value: 30 },
      freeSpins: { value: 200 },
    },
    license: "Estonia",
    payNplay: true,
  },
  {
    name: "ultra",
    type: "bonus",
    img: Ultra,
    link: "https://afftrackuc.21.partners/C.ashx?btag=a_17791b_958c_&affid=4204&siteid=17791&adid=958&c=",
    perks: {
      wager: { value: 35 },
      freeSpins: { value: 20, condition: "Use code ULTRA1" },
      bonus: { percentage: 100, maxSum: 200 },
    },
    license: "Estonia",
    payNplay: true,
  },
  {
    name: "winnerz",
    type: "bonus",
    img: Winnerz,
    link: "https://click.winnerz.partners/afs/come.php?cid=12409&ctgid=100&atype=1&brandid=9",
    perks: {
      wager: { value: 30 },
      freeSpins: { value: 200 },
    },
    license: "Estonia",
    payNplay: true,
  },
  {
    name: "hejgo",
    type: "bonus",
    img: Hejgo,
    link: "https://hejgoplay.com/j9d8ee279",
    perks: {
      wager: { value: 40 },
      freeSpins: { value: 100 },
    },
    license: "MGA",
    payNplay: true,
  },
  {
    name: "rebellion",
    type: "bonus",
    img: Rebellion,
    link: "https://rebellionplay.com/j0b04e0cd",
    license: "Curacao",
    perks: {
      wager: { value: 40 },
      freeSpins: { value: 100 },
      bonus: {
        maxSum: 1000,
        percentage: 100,
        condition: "Use code: RBW1",
      },
    },
  },
  {
    name: "richprize",
    type: "bonus",
    img: Richprize,
    link: "https://rp.gold/iFzxi",
    license: "Curacao",
    perks: {
      wager: { value: 12 },
      freeSpins: { value: 50 },
      bonus: {
        maxSum: 3000,
        percentage: 300,
      },
    },
  },
  {
    name: "together",
    type: "bonus",
    img: Together,
    link: "https://record.nolimitpartners.com/_KvW4Zr-dEDeVAv0U_Fv2nWNd7ZgqdRLk/71/",
    perks: {
      wager: { value: 40 },
      freeSpins: { value: 100 },
      bonus: {
        maxSum: 300,
        percentage: 100,
      },
    },
    license: "Curacao",
    payNplay: false,
  },
  {
    name: "slotspalace",
    type: "bonus",
    img: SlotsPalace,
    link: "https://slp.servclick1move.com/?mid=709_597017",
    perks: {
      wager: { value: 35 },
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
    },
    license: "Estonia",
    payNplay: true,
  },
  {
    name: "7signs",
    type: "bonus",
    img: SevenSigns,
    link: "https://sign.servclick1move.com/?mid=33481_597025",
    perks: {
      wager: { value: 35 },
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
    },
    license: "Estonia",
    payNplay: true,
  },
  {
    name: "myempire",
    type: "bonus",
    img: MyEmpire,
    link: "https://myemp.servclick1move.com/?mid=709_597021",
    perks: {
      wager: { value: 35 },
      bonus: {
        maxSum: 240,
        percentage: 100,
      },
    },
    license: "Curacao",
    payNplay: true,
  },
  {
    name: "fivegringos",
    type: "bonus",
    img: FiveCringos,
    link: "https://fgr.servclick1move.com/?mid=33481_597029",
    perks: {
      wager: { value: 35 },
      freeSpins: { value: 200 },
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
    },
    license: "Estonia",
    payNplay: true,
  },
  {
    name: "lemon",
    type: "bonus",
    img: Lemon,
    link: "https://m.lemon.partners/Redirect.aspx?mid=5&sid=3612&cid=&pid=&affid=2827",
    perks: {
      freeSpins: { value: 100 },
      wager: { value: 40 },
      bonus: {
        maxSum: 300,
        percentage: 100,
      },
    },
    license: "Curacao",
  },
  {
    name: "friday",
    type: "bonus",
    img: Friday,
    link: "https://tinyurl.com/duofriday",
    perks: {
      freeSpins: { value: 200 },
      bonus: {
        maxSum: 200,
        percentage: 100,
      },
      wager: { value: 40 },
    },
    license: "Curacao",
  },
  {
    name: "smokace",
    type: "bonus",
    img: Smokace,
    link: "https://record.joinaff.com/_HuDMBlylonIQii3_-zHRAGNd7ZgqdRLk/1/",
    perks: {
      freeSpins: { value: 100 },
      wager: { value: 45 },
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
    },
    license: "Curacao",
  },
  {
    name: "windetta",
    type: "bonus",
    img: Windetta,
    link: "https://record.joinaff.com/_HuDMBlylonL8iimmbG_332Nd7ZgqdRLk/1/",
    perks: {
      wager: { value: 40 },
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
    },
    license: "Curacao",
  },
  {
    name: "winlegends",
    type: "bonus",
    img: Winlegends,
    link: "https://record.joinaff.com/_HuDMBlylonL9bPK5GzPuJWNd7ZgqdRLk/1/",
    perks: {
      wager: { value: 40 },
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
    },
    license: "Curacao",
  },
];

export default bonuses;
